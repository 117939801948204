import * as React from 'react';

import {
  Datagrid,
  List,
  SearchInput,
  SelectField,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';
import PhoneField from '../customers/PhoneField';

const filters = [<SearchInput source="q" alwaysOn />];

const UsersList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      title={translate('pos.menu.users')}
      filters={!isSmall ? filters : undefined}
      sort={{ field: 'signUpDate', order: 'DESC' }}
      perPage={25}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.users.fields.name')}
          />
          <TextField
            source="email"
            label={translate('resources.users.fields.email')}
          />
          <PhoneField
            source="phone"
            label={translate('resources.users.fields.phone')}
          />
          <SelectField
            source="role"
            label={translate('resources.users.fields.role')}
            choices={[
              {
                id: 'admin',
                name: translate('resources.users.fields.roles.admin'),
              },
              {
                id: 'manager',
                name: translate('resources.users.fields.roles.manager'),
              },
            ]}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default UsersList;
