import * as React from 'react';

import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { IMarketingCategory } from '../../../types';
import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';

const Title = () => {
  const record = useRecordContext<IMarketingCategory>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.marketing_materials.categories.editText')}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

const CategoriesEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        {/* <TextInput
          source="name"
          label={translate('resources.marketing_materials.fields.name')}
          validate={[required()]}
        /> */}
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate(
              'resources.marketing_materials.categories.fields.name'
            )}
            validate={[required()]}
          />
        </MyTranslatableInputs>
        <NumberInput
          source="order"
          label={translate(
            'resources.marketing_materials.categories.fields.order'
          )}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CategoriesEdit;
