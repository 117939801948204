import * as React from 'react';

import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import { IPaymentReport } from '../../types';
import MobileGrid from './MobileGrid';

const filters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Клиент"
    source="client_id"
    reference="customers"
    allowEmpty
    perPage={10000}
  >
    {/* <SelectInput optionText="name" /> */}
    <AutocompleteInput
      label="Клиент"
      optionText={(record) =>
        record
          ? `${record.name} ${
              record.companyName && '(' + record.companyName + ')'
            } - ${record.phone}`
          : ''
      }
    />
  </ReferenceInput>,
];

const PendingRequestsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      title={translate('pos.menu.payment_reports')}
      perPage={25}
      sort={{ field: 'date', order: 'DESC' }}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <ReferenceField
            source="clientId"
            label={translate('resources.payment_reports.fields.clientId')}
            reference="customers"
          >
            <FunctionField
              render={(record: IPaymentReport) =>
                record
                  ? `${record.name} ${
                      record.companyName && '(' + record.companyName + ')'
                    } - ${record.phone}`
                  : ''
              }
            />
          </ReferenceField>
          
          <TextField
            source="name"
            label={'Имя'}
          />
          <TextField
            source="companyName"
            label={'Компания'}
          />
          <TextField
            source="city"
            label={'Город'}
          />
          <TextField
            source="fgascertificate"
            label={'F-Gas certificate'}
          />
          <DateField source="date" label={'Дата'} showTime />
        </Datagrid>
      )}
    </List>
  );
};

export default PendingRequestsList;
