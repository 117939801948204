import * as React from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const SettingsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List bulkActionButtons={false} title={translate('pos.menu.settings')}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.settings.fields.name')}
          />
          <BooleanField
            source="maintenance"
            label={translate('resources.settings.fields.maintenance')}
          />
          <BooleanField
            source="usePayments"
            label={translate('resources.settings.fields.usePayments')}
          />
          <BooleanField
            source="useBonuses"
            label={translate('resources.settings.fields.useBonuses')}
          />
          <BooleanField
            source="allowSwitchCurrency"
            label={translate('resources.settings.fields.allowSwitchCurrency')}
          />
          <DateField
            source="date"
            label={translate('resources.settings.fields.date')}
            showTime
          />
          <NumberField
            source="initialBonus"
            label="Приветственный бонус"
            options={{
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default SettingsList;
