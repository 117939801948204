import * as React from 'react';

import {
  Create,
  FileField,
  FileInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';

const CategoriesCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <ReferenceInput
          source="category"
          reference="marketing-categories"
          validate={[required()]}
          perPage={1000}
        >
          <SelectInput
            optionText="name"
            label="Категория"
            validate={[required()]}
            style={{ width: 200 }}
          />
        </ReferenceInput>
        {/* <TextInput
          source="name"
          label={translate('resources.marketing_materials.fields.name')}
          validate={[required()]}
        /> */}
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate('resources.marketing_materials.items.fields.name')}
            // validate={[required()]}
            style={{ width: 300 }}
          />
        </MyTranslatableInputs>
        <SelectInput
          source="itemType"
          label={translate(
            'resources.marketing_materials.items.fields.itemType'
          )}
          choices={[
            {
              id: 'pdf',
              name: translate(
                'resources.marketing_materials.items.fields.file'
              ),
            },
            {
              id: 'image',
              name: translate(
                'resources.marketing_materials.items.fields.image'
              ),
            },
          ]}
          validate={[required()]}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.itemType === 'pdf' ? (
              <FileInput
                source="image"
                label={translate(
                  'resources.marketing_materials.items.fields.file'
                )}
                accept="application/pdf"
                validate={[required()]}
              >
                <FileField source="src" title="title" />
              </FileInput>
            ) : formData.itemType === 'image' ? (
              <ImageInput
                source="image"
                label={translate(
                  'resources.marketing_materials.items.fields.image'
                )}
                accept="image/*"
                multiple={false}
                validate={[required()]}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            ): null
          }
        </FormDataConsumer>

        <NumberInput
          source="order"
          label={translate('resources.marketing_materials.items.fields.order')}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Create>
  );
};

export default CategoriesCreate;
