import * as React from 'react';

import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  maxValue,
  minValue,
  number,
  required,
  useTranslate,
} from 'react-admin';

import { InputAdornment } from '@mui/material';

const SettingsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('resources.settings.fields.name')}
          validate={[required()]}
        />
        <BooleanInput
          source="maintenance"
          label={translate('resources.settings.fields.maintenance')}
        />
        <BooleanInput
          source="usePayments"
          label={translate('resources.settings.fields.usePayments')}
        />
        <BooleanInput
          source="useBonuses"
          label={translate('resources.settings.fields.useBonuses')}
        />
        <BooleanInput
          source="allowSwitchCurrency"
          label={translate('resources.settings.fields.allowSwitchCurrency')}
        />
        <NumberInput
          label="Приветственный бонус"
          source="initialBonus"
          max={100}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(100)]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">€</InputAdornment>
            ),
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default SettingsCreate;
