import * as React from 'react';

import {
  BooleanInput,
  Edit,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  number,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { InputAdornment } from '@mui/material';

const Title = () => {
  const translate = useTranslate();
  return <span>{translate('resources.settings.name')}</span>;
};

const EditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const SettingsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput
          source="name"
          label={translate('resources.settings.fields.name')}
          validate={[required()]}
          disabled
        />
        <BooleanInput
          source="maintenance"
          label={translate('resources.settings.fields.maintenance')}
        />
        <BooleanInput
          source="usePayments"
          label={translate('resources.settings.fields.usePayments')}
        />
        <BooleanInput
          source="useBonuses"
          label={translate('resources.settings.fields.useBonuses')}
        />
        <BooleanInput
          source="allowSwitchCurrency"
          label={translate('resources.settings.fields.allowSwitchCurrency')}
        />
        <NumberInput
          label="Приветственный бонус"
          source="initialBonus"
          max={100}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(100)]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">€</InputAdornment>
            ),
          }}
        />
        <NumberInput
          label="Реферальный бонус"
          source="referralBonus"
          max={50}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(50)]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">%</InputAdornment>
            ),
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default SettingsEdit;
