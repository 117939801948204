import { Config } from '../config';
import { TranslatableInputs } from 'react-admin';
import { styled } from '@mui/system';

type Props = {
  children: any;
  groupKey: string;
  singleLocale?: boolean;
  // fullWidth?: boolean;
};

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
}));

export const MyTranslatableInputs = (props: Props) => {
  return (
    <Content>
      <TranslatableInputs
        locales={props.singleLocale ? ['en'] :  Config.locales}
        defaultLocale={Config.defaultLocale}
        groupKey={props.groupKey}
      >
        {props.children}
      </TranslatableInputs>
    </Content>
  );
};

// export const MyTranslatableInputs = styled(MyTranslatableInputsWS)({
//   backgroundColor: "Lavender",
//   "& .RaDatagrid-headerCell": {
//       backgroundColor: "MistyRose",
//   },
// });
