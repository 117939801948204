import * as React from 'react';

import {
  Datagrid,
  List,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const CategoriesList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List title={translate('pos.menu.marketing_materials.categories')}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="nameTranslated.en"
            label={translate('resources.marketing_materials.items.fields.name')}
          />
          <TextField
            source="itemType"
            label={translate('resources.marketing_materials.items.fields.itemType')}
          />
          <TextField
            source="order"
            label={translate('resources.marketing_materials.items.fields.order')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default CategoriesList;
