import { Card, CardContent } from '@mui/material';

import React from 'react';
import { Title } from 'react-admin';

const DeleteAccountPage = () => {
  return (
    <Card>
      <Title title="Delete user" />
      <CardContent>
        <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
          <h1>User Account Deletion</h1>
          <div
            style={{
              backgroundColor: '#F7F3DA',
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 10,
              padding: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 12, marginLeft: 6, fontSize: 25 }}>
              ⓘ
            </div>
            <div>
              By deleting the data of your account from our system, you will
              lose access to the features of our digital products that require
              an account.
            </div>
          </div>
          <div
            style={{ maxWidth: 'none', width: '100%', wordWrap: 'break-word' }}
          >
            <p>
              - To delete your account via the mobile application, go to the
              "Personal Account" section. To do this, follow these steps: touch
              the personal account icon in the upper right corner of the screen.
              Then select the "Delete Account" option by pressing the
              corresponding button.
            </p>
            <p>
              - To delete your account without using the mobile application, you
              should send a deletion request to the administrator's email,
              including the necessary information and contact details. Here is
              an example text that can be used in such a request:
            </p>
            <hr />
            <p>
              <strong>Subject: Account Deletion Request</strong>
            </p>
            <p>Dear support/administration of [service name],</p>
            <p>
              I am requesting the deletion of my account on your platform.
              Please take the necessary steps for the complete removal of my
              account.
            </p>
            <p>Account information:</p>
            <ul>
              <li>Username: [your username]</li>
              <li>Phone number: [your phone number]</li>
              <li>Email associated with the account: [your email address]</li>
            </ul>
            <p>
              Reason for deletion: [provide a brief reason why you want to
              delete the account]
            </p>
            <p>
              Kindly notify me of the successful completion of the account
              deletion process through a reply email or notification in the
              system.
            </p>
            <p>Thank you for providing this service.</p>
            <p>Sincerely, [Your name]</p>
            <hr />
            <p>
              Email for contacting the administrator:{' '}
              <a href="mailto:info@sensei-europe.com">info@sensei-europe.com</a>
            </p>
            <br />
          </div>
          <p>
            We hope the information provided here will be helpful to you. If you
            have any questions, feel free to reach out to us.
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default DeleteAccountPage;
