import * as React from 'react';

import {
  AutocompleteInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NullableBooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { Divider } from '@mui/material';
import { format } from 'date-fns';

const PendingRequestsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <DateTimeInput
          source="date"
          label={translate('resources.pending_requests.fields.date')}
          validate={[required()]}
          disabled
        />
        <ReferenceInput
          source="clientId"
          reference="customers"
          allowEmpty
          perPage={1000}
        >
          <SelectInput
            disabled
            label={translate('resources.pending_requests.fields.clientId')}
            optionText={(record) =>
              record
                ? `${record.name} ${
                    record.companyName && '(' + record.companyName + ')'
                  } - ${record.phone}`
                : ''
            }
            fullWidth
          />
        </ReferenceInput>
        <TextInput
          source="name"
          disabled
          label={translate('resources.pending_requests.fields.name')}
        />
        <TextInput
          source="companyName"
          disabled
          label={translate('resources.pending_requests.fields.companyName')}
        />
        <TextInput
          source="city"
          disabled
          label={translate('resources.pending_requests.fields.city')}
        />
        <TextInput
          source="fgascertificate"
          disabled
          label={translate('resources.pending_requests.fields.fgascertificate')}
        />
        <NullableBooleanInput
          style={{ width: 160 }}
          source="verify"
          label={translate('resources.pending_requests.fields.verify')}
          disabled
        />
      </SimpleForm>
    </Create>
  );
};

export default PendingRequestsCreate;
