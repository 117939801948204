import * as React from 'react';

import {
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Box } from '@mui/material';
import { IMarketingCategory } from '../../../types';
import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';

const Title = () => {
  const record = useRecordContext<IMarketingCategory>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.marketing_materials.categories.editText')}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

const CategoriesEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <ReferenceInput
          source="category"
          reference="marketing-categories"
          validate={[required()]}
          perPage={1000}
        >
          <SelectInput
            optionText="name"
            label="Категория"
            validate={[required()]}
            style={{ width: 200 }}
          />
        </ReferenceInput>
        {/* <TextInput
          source="name"
          label={translate('resources.marketing_materials.fields.name')}
          validate={[required()]}
        /> */}
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate('resources.marketing_materials.items.fields.name')}
            validate={[required()]}
            style={{ width: 300 }}
          />
        </MyTranslatableInputs>
        <SelectInput
          disabled={true}
          source="itemType"
          label={translate(
            'resources.marketing_materials.items.fields.itemType'
          )}
          choices={[
            {
              id: 'pdf',
              name: translate(
                'resources.marketing_materials.items.fields.file'
              ),
            },
            {
              id: 'image',
              name: translate(
                'resources.marketing_materials.items.fields.image'
              ),
            },
          ]}
          validate={[required()]}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.itemType === 'pdf' ? (
              <>
                <Labeled
                  label={translate(
                    'resources.marketing_materials.items.fields.curr_file'
                  )}
                >
                  <FileField source="file" title="File" target="_blank" />
                </Labeled>
                <FileInput
                  source="image"
                  label={translate(
                    'resources.marketing_materials.items.fields.file'
                  )}
                  accept="application/pdf"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </>
            ) : formData.itemType === 'image' ? (
              <>
                <Labeled
                  label={translate(
                    'resources.marketing_materials.items.fields.curr_image'
                  )}
                >
                  <ImageField source="file" />
                </Labeled>
                <ImageInput
                  source="image"
                  label={translate(
                    'resources.marketing_materials.items.fields.image_new'
                  )}
                  accept="image/*"
                  multiple={false}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </>
            ) : null
          }
        </FormDataConsumer>

        <Separator />
        <NumberInput
          source="order"
          label={translate('resources.marketing_materials.items.fields.order')}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Edit>
  );
};

const Separator = () => <Box pt="1em" />;

export default CategoriesEdit;
