import * as React from 'react';

import {
  Edit,
  FileField,
  FileInput,
  Labeled,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Box } from '@mui/material';
import { ICatalogsPdf } from '../../types';
import { MyTranslatableInputs } from '../../components/MyTranslatableInputs';

const Title = () => {
  const record = useRecordContext<ICatalogsPdf>();
  return <span>PDF каталог {record ? `"${record.name}"` : ''}</span>;
};

const CatalogsPdfEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate('resources.catalogs_pdf.fields.name')}
            validate={[required()]}
            fullWidth
          />
        </MyTranslatableInputs>
        <SelectInput
          source="section"
          label={translate('resources.catalogs_pdf.fields.section')}
          choices={[
            {
              id: 'instructions',
              name: translate('resources.catalogs_pdf.fields.sections.instructions'),
            },
            {
              id: 'catalogs',
              name: translate('resources.catalogs_pdf.fields.sections.catalogs'),
            },
          ]}
          validate={[required()]}
        />
        <FileInput
          source="image"
          label={translate('resources.catalogs_pdf.fields.image')}
          accept="application/pdf"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <Labeled label={translate('resources.catalogs_pdf.fields.image_curr')}>
          <FileField source="file" title="name" target="_blank" />
        </Labeled>
        <Separator />
        <NumberInput
          source="order"
          label={translate('resources.catalogs_pdf.fields.order')}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Edit>
  );
};

const Separator = () => <Box pt="1em" />;

export default CatalogsPdfEdit;
