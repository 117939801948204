import WarrantiesCreate from './WarrantiesCreate';
import WarrantiesEdit from './WarrantiesEdit';
import WarrantiesIcon from '@mui/icons-material/VerifiedUser';
import WarrantiesList from './WarrantiesList';

export default {
  list: WarrantiesList,
  edit: WarrantiesEdit,
  create: WarrantiesCreate,
  icon: WarrantiesIcon,
};
