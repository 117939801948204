import * as React from 'react';

import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Divider } from '@mui/material';
import { IContacts } from '../../types';

const Title = () => {
  const record = useRecordContext<IContacts>();
  return <span>Контакты</span>;
};

const EditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const ContactsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput
          label={translate('resources.contacts.fields.office.label')}
          source="office.label"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lat')}
          source="office.lat"
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.office.lng')}
          source="office.lng"
          validate={[required()]}
        />
        <TextInput
          source="phoneNumber"
          label={translate('resources.contacts.fields.phoneNumber')}
          validate={[required()]}
        />
        <TextInput
          source="whatsapp"
          label={translate('resources.contacts.fields.whatsapp')}
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.contacts.fields.email')}
          source="email"
          validate={[required()]}
          type="email"
        />
        <Divider />
        <TextInput
          label={translate(
            'resources.contacts.fields.supportPhoneNumber'
          )}
          source="supportPhoneNumber"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ContactsEdit;
