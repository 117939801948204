import * as React from 'react';

import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import {
  DateField,
  EditButton,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useListContext,
  useTranslate,
} from 'react-admin';

import { ISerie } from '../../../types';

const MobileGrid = () => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext<ISerie>();

  if (isLoading || data.length === 0) {
    return null;
  }

  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader
              title={`${record.nameTranslated.en}`}
              action={<EditButton />}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                {translate('resources.series.fields.section')}
                :&nbsp;
                <ReferenceField
                  source="section"
                  label={translate('resources.series.fields.section')}
                  reference="sections"
                >
                  <TextField source="name" />
                </ReferenceField>
              </Typography>
              <Typography variant="body2">
                {translate('resources.series.fields.category')}
                :&nbsp;
                <ReferenceField
                  source="category"
                  label={translate('resources.series.fields.category')}
                  reference="categories"
                >
                  <TextField source="name" />
                </ReferenceField>
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;
