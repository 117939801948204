import * as React from 'react';

import {
  Datagrid,
  EditButton,
  ImageField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import LinkToRelatedProducts from './LinkToRelatedProducts';
import MobileGrid from './MobileGrid';

const filters = [
  <SearchInput source="q" alwaysOn />,
  // <ReferenceInput
  //   label="Раздел"
  //   source="section_q"
  //   reference="sections"
  //   allowEmpty
  //   perPage={1000}
  // >
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
  <ReferenceInput
    label="Категория"
    source="category_q"
    reference="categories"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" label="Категория" />
  </ReferenceInput>,
];

const SeriesList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={false}
      title={translate('pos.menu.series')}
      filters={!isSmall ? filters : undefined}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="nameTranslated.en"
            label={translate('resources.series.fields.name')}
          />
          <ReferenceField
            source="section"
            label={translate('resources.series.fields.section')}
            reference="sections"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="category"
            label={translate('resources.series.fields.category')}
            reference="categories"
          >
            <TextField source="name" />
          </ReferenceField>
          <ImageField
            source="image"
            label={translate('resources.series.fields.photo')}
            className="series_image"
          />
          <TextField
            source="order"
            label={translate('resources.series.fields.order')}
          />
          <LinkToRelatedProducts />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default SeriesList;
