import * as React from 'react';

import { FieldProps, useRecordContext } from 'react-admin';

import { Customer } from '../../types';

const PhoneField = (props: FieldProps) => {
  const record = useRecordContext<Customer>();
  if (!record) return null;
  const renderPhoneNumber = () => {
    if (record?.phone) {
      const x = record.phone.replace(/\D/g, '').match(/(\d{2})(\d{3})(\d{3})(\d{4})/);
      if (x) {
        return '+' + x[1] + ' ' + x[2] + ' ' + x[3] + '-' + x[4];
      }
      return `+ ${record.phone}`;
    }
    return '';
  };
  return (
    <span className="MuiTypography-root MuiTypography-body2">
      {renderPhoneNumber()}
    </span>
  );
};

export default PhoneField;
