import * as React from 'react';

import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const DiscountsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List bulkActionButtons={false} title={translate('pos.menu.discounts')}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.discounts.fields.name')}
          />
          <TextField
            source="discount"
            label={translate('resources.discounts.fields.discount')}
          />
          <BooleanField
            source="allowPayments"
            label={translate('resources.discounts.fields.allowPayments')}
          />
          <BooleanField
            source="allowWarranty"
            label={translate('resources.discounts.fields.allowWarranty')}
          />
          <BooleanField
            source="allowBonus"
            label={translate('resources.discounts.fields.allowBonus')}
          />
          <TextField
            source="bonusPeriod"
            label={translate('resources.discounts.fields.bonusPeriod')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default DiscountsList;
