import * as React from 'react';

import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import { IPaymentReport } from '../../types';
import MobileGrid from './MobileGrid';

const filters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Клиент"
    source="client_id"
    reference="customers"
    allowEmpty
    perPage={10000}
  >
    {/* <SelectInput optionText="name" /> */}
    <AutocompleteInput
      label="Клиент"
      optionText={(record) =>
        record
          ? `${record.name} ${
              record.companyName && '(' + record.companyName + ')'
            } - ${record.phone}`
          : ''
      }
    />
  </ReferenceInput>,
];

const PaymentReportsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      title={translate('pos.menu.payment_reports')}
      perPage={25}
      sort={{ field: 'date', order: 'DESC' }}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <ReferenceField
            source="clientId"
            label={translate('resources.payment_reports.fields.clientId')}
            reference="customers"
          >
            <FunctionField
              render={(record: IPaymentReport) =>
                record
                  ? `${record.name} ${
                      record.companyName && '(' + record.companyName + ')'
                    } - ${record.phone}`
                  : ''
              }
            />
          </ReferenceField>
          <ReferenceField
            source="orderId"
            label={translate('resources.payment_reports.fields.orderId')}
            reference="orders"
          >
            <TextField source="orderId" />
          </ReferenceField>
          <SelectField
            source="paymentMethod"
            label={translate('resources.payment_reports.fields.paymentMethod')}
            choices={[
              {
                id: 'fop',
                name: translate('resources.payment_reports.fields.payment.fop'),
              },
              {
                id: 'payment_account',
                name: translate(
                  'resources.payment_reports.fields.payment.payment_account'
                ),
              },
              {
                id: 'mobile_app',
                name: translate(
                  'resources.payment_reports.fields.payment.mobile_app'
                ),
              },
            ]}
          />
          <NumberField
            source="paymentValueInUSD"
            label={translate('resources.payment_reports.fields.paymentValueInUSD')}
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
          />
          <NumberField
            source="paymentValue"
            label={translate('resources.payment_reports.fields.paymentValue')}
            options={{
              style: 'currency',
              currency: 'PLN',
            }}
          />
          <DateField
            source="paymentDate"
            label={translate('resources.payment_reports.fields.paymentDate')}
            showTime
          />
          <DateField
            source="date"
            label={translate('resources.payment_reports.fields.date')}
            showTime
          />
          <DateField
            source="changedDate"
            label={translate('resources.payment_reports.fields.changedDate')}
            showTime
          />
          <BooleanField
            source="processed"
            label={translate('resources.payment_reports.fields.processed')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default PaymentReportsList;
