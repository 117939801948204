import { TranslationMessages } from 'react-admin';
import russianMessages from 'ra-language-russian';

const customRussianMessages: TranslationMessages = {
  ...russianMessages,
  ra: {
    ...russianMessages.ra,
    configurable: {
      customize: 'Изм',
    },
    locales: {
      ru: 'RU',
      ua: 'UA',
      pl: 'PL',
      en: 'EN',
    },
    auth: {
      ...russianMessages.ra.auth,
      phone: 'Номер телефона',

    },
  },
  pos: {
    configuration: 'Настройки',
    phone: 'Номер телефона',
    language: 'Язык',
    configure_users: 'Управление',
    users_link: 'Пользователи',
    theme: {
      name: 'Тема',
      light: 'Светлая',
      dark: 'Темная',
    },
    dashboard: {
      monthly_revenue: 'Месячный доход',
      month_history: 'Доход за 30 дней',
      new_orders: 'Новых заказов',
      new_customers: 'Новых клиентов',
      all_customers: 'Открыть все',
      pending_orders: 'Заказы в ожидании',
      exchange_rate: 'Курс валют',
      items_count: 'Кол-во товаров',
      new_users: 'Последние зарегистрированные пользователи',
      order: {
        items:
          'от %{customer_name}, один товар |||| от %{customer_name}, %{nb_items} товара',
      },
    },
    menu: {
      users: 'Пользователи',
      customers: 'Клиенты',
      rates: 'Курс Валют',
      discounts: 'Категории клиентов',
      catalog: 'Каталог',
      sections: 'Раздел',
      categories: 'Категории',
      series: 'Серии',
      items: 'Товары',
      orders: 'Заказы',
      errorcodes: 'Коды ошибок',
      catalogs_pdf: 'Инструкции',
      articles: 'Статьи',
      conditioners: 'Прайс кондиционеры',
      notifications: 'Push уведомления',
      recurring_notifications: 'Push рассылки',
      contacts: 'Контакты',
      price: 'Email рассылки',
      settings: 'Настройки',
      payment_reports: 'Сообщения об оплате',
      pending_requests: 'Заявки на рассмотрении',
      warranties: 'Гарантии',
      marketing_materials: {
        name: 'Маркетинговые материалы',
        categories: 'Категории',
        items: 'Материалы'
      }
    },
  },
  resources: {
    items: {
      editText: 'Товар',
      tabs: {
        main: 'Основное',
        description: 'Описание',
        advantages: 'Основные преимущества',
        photo: 'Фото',
        specifications: {
          title: 'Технические характеристики',
          main: 'Основное',
          dimensions_inner: 'Размеры внутреннего блока',
          dimensions_outer: 'Размеры внешнего блока',
          dimensions_box: 'Размеры упаковки',
          cooling: 'Охлаждение',
          heating: 'Обогрев',
          pressure: 'Давление',
          room_temperature: 'Температура помещения',
          compressor: 'Компрессор',
          refrigerant_type: 'Тип хладагента / Количество',
          pipe_diameter: 'Диаметр труб',
          temperature_range: 'Диапазон рабочих температур',
          performance: 'Производительность',
          energy_efficiency: 'Уровень энергоэффективности',
          power_consumption: 'Потребляемая мощность',
          consumption_current: 'Потребляетый ток',
          pipes: 'Трубы',
        },
      },
      fields: {
        key: 'Ключ',
        value: 'Значение',
        title: 'Заголовок',
        text: 'Текст',
        image: 'Изображение',
      },
    },
    sections: {
      name: 'Разделы',
      editText: 'Раздел',
      fields: {
        series: 'Серии',
        name: 'Раздел',
        currency: 'Валюта',
        order: 'Порядок',
        showErrorCodes: 'Показывать коды ошибок',
      },
    },
    categories: {
      name: 'Категории',
      editText: 'Категория',
      fields: {
        series: 'Серии',
        name: 'Название категории',
        section: 'Раздел',
        order: 'Порядок',
      },
    },
    series: {
      name: 'Серии',
      editText: 'Серия',
      fields: {
        items: 'Товары',
        name: 'Название серии',
        section: 'Раздел',
        category: 'Категория',
        photo: 'Фото',
        order: 'Порядок',
        description: 'Описание',
        image: 'Текущее Фото',
        image_new: 'Новое Фото',
        itemImage_1: 'Текущие изображение 1',
        itemImage_2: 'Текущие изображение 2',
        itemImage_3: 'Текущие изображение 3',
      },
      tabs: {
        main: 'Основное',
        photo: 'Фото',
      },
    },
    commands: {
      name: 'Заказы',
      title: 'Заказы',
      section: {
        order: 'Заказ',
        customer: 'Клиент',
        shipping_address: 'Адрес доставки',
        next_bonus: 'Бонус на следующий заказ',
        payment: 'Оплата',
        items: 'Товары',
        totals: 'Итоги',
        total: 'Итог',
      },
      fields: {
        nb_items: 'К-во',
        items: {
          reference: 'Наименование',
          unit_price_usd: 'Цена, €',
          unit_price: 'Цена, zł',
          rate: 'Курс покупки',
          quantity: 'Кол-во',
          summInUSD: 'Сумма, €',
          summ: 'Сумма, zł',
          total: 'Всего',
          sum: 'Сумма',
          delivery: 'Доставка',
          bonuses: 'Бонусами',
          totalSumm: 'Сумма',
        },
        status: 'Статус',
        returned: 'Возврат',
        date: 'Дата',
        ordered: 'Заказан',
        delivered: 'Доставлен',
        cancelled: 'Отменен',
      },
    },
    customers: {
      name: 'Клиент',
      fields: {
        name: 'Клиент',
        phone_number: 'Номер телефона',
        discount: 'Категория цен',
      },
    },
    rates: {
      name: 'Валюты',
      fields: {
        name: 'Валюта',
        cashRate: 'Нал',
        cashlessRate: 'Безнал',
        date: 'Дата изменения',
      },
    },
    discounts: {
      name: 'Категории клиентов',
      fields: {
        name: 'Наименование',
        discount: 'Скидка %',
        allowPayments: 'Оплата',
        allowBonus: 'Бонусы',
        bonusPeriod: 'Начисление бонусов',
        allowWarranty: 'Гарантии',
      },
    },
    error_codes: {
      name: 'Коды ошибок',
      fields: {
        name: 'Ошибка',
        description: 'Описание',
        serie: 'Серия',
        category: 'Категория',
        section: 'Раздел',
        order: 'Порядок',
      },
    },
    catalogs_pdf: {
      name: 'Инструкции',
      fields: {
        name: 'Название файла',
        section: 'Секция',
        sections: {
          instructions: 'Инструкции',
          catalogs: 'Каталоги',
        },
        order: 'Порядок',
        date: 'Дата добавления',
        image: 'Файл PDF',
        image_curr: 'Текущий файл PDF',
      },
    },
    notifications: {
      name: 'Push Уведомления',
      fields: {
        name: 'Заголовок',
        image: 'Фото',
        date: 'Дата изменения',
      },
    },
    recurring_notifications: {
      name: 'Push рассылки',
      fields: {
        name: 'Название',
        notificationTitle: 'Заголовок push увед.',
        notificationText: 'Подзаголовок push увед.',
        sendTo: 'Получатель',
        active: 'Активирован',
        active_alt: 'Активировать (Рассылка каждый понедельник в 11:00)',
        date: 'Дата изменения',
      },
    },
    contacts: {
      name: 'Контакты',
      fields: {
        phoneNumber: 'Телефон',
        whatsapp: 'WhatsApp',
        supportPhoneNumber: 'Телефон службы поддержки',
        office: {
          label: 'Адрес',
          lat: 'Офис, широта',
          lng: 'Офис, долгота',
        },
        email: 'Email',
      },
    },
    price: {
      name: 'Email рассылки',
      fields: {
        name: 'Название',
        file: 'Файл',
        curr_file: 'Текущий Файл',
        date: 'Дата изменения',
        active: 'Активирован',
        active_alt: 'Активировать (Рассылка каждый понедельник в 10:00)',
        topic: 'Тема письма',
        description: 'Текст письма',
        image: 'Файл (.xlsx)',
        theme: 'Тема письма (оставьте поле пустым что б использовать стандартную запись: "Новый прайс от ДД.ММ.ГГГГ")'
      },
    },
    settings: {
      name: 'Настройки приложения',
      fields: {
        name: 'Наименование',
        maintenance: 'Технические работы',
        usePayments: 'Оплата',
        useBonuses: 'Бонусы',
        allowSwitchCurrency: ' Разрешить переключение валюты',
        date: 'Дата изменения',
      },
    },
    payment_reports: {
      name: 'Сообщения об оплате',
      fields: {
        clientId: 'Клиент',
        orderId: 'Заказ',
        paymentMethod: 'Способ оплаты',
        paymentValue: 'Сумма',
        paymentValueInUSD: 'Сумма, €',
        paymentDate: 'Дата оплаты',
        changedDate: 'Дата изменения',
        date: 'Дата создания',
        processed: 'Обработано',
        processed_alt: 'Обработано (оплата будет применена к заказу)',
        payment: {
          fop: 'Оплата на карту',
          payment_account: 'На расчетный счет',
          mobile_app: 'Мобильное приложение',
        },
        applyToOrder: 'Привязать к заказу',
      },
    },
    pending_requests: {
      name: 'Заявка',
      fields: {
        clientId: 'Клиент',
        name: 'Имя',
        city: 'Город',
        country: 'Страна',
        region: 'Регион',
        companyName: 'Компания',
        fgascertificate: 'F-Gas certificate',
        date: 'Дата создания',
        verify: 'Принять',
      },
    },
    users: {
      name: 'Пользователи',
      editText: 'Пользователь',
      fields: {
        name: 'Имя',
        email: 'Email',
        phone: 'Номер телефона',
        role: 'Роль',
        roles: {
          admin: 'Администратор',
          manager: 'Менеджер',
          user: 'Пользователь'
        },
        chPass: "Изменить пароль",
        password: "Введите новый пароль",
        pass_text: "Введите новый пароль, затем отправьте его пользователю вручную. Пароль будет доступен для просмотра только 1 раз"
      },
    },
    warranties: {
      name: 'Гарантии',
      editText: 'Гарантия',
      fields: {
        clientId: 'Исполнитель',
        equipmentType: 'Тип оборудования',
        equipmentName: 'Наименование',
        code: 'Серийный номер (внутр.)',
        code2: 'Серийный номер (нар.)',
        status: 'Статус',
        date: 'Дата создания',
        nextDate: 'Дата след. обсл.',
        warantyPeriod: 'Гарантийный срок, мес',
        clientData: {
          firstName: 'Имя',
          lastName: 'Фамилия',
          phoneNumber: 'Телефон',
          email: 'Email',
          address: 'Адрес',
        },
        statuses: {
          active: 'Активная',
          finished: 'Завершен',
        },
        equipmentTypes: {
          air_conditioner: 'Кондиционер',
          heat_pump: 'Помпа тепла',
        },
      },
    },
    marketing_materials: {
      categories: {
        name: 'Категории',
        editText: 'Категория',
        fields: {
          name: 'Название категории',
          order: 'Порядок',
        },
      },
      items: {
        name: 'Материалы',
        editText: 'Материал',
        fields: {
          name: 'Название материала',
          itemType: 'Тип',
          file: 'Файл',
          curr_file: 'Текущий Файл',
          image: 'Изображение',
          curr_image: 'Текущее Изображение',
          image_new: 'Новое Изображение',
          date: 'Дата изменения',
          order: 'Порядок',
        },
      },
    },
  },
};

export default customRussianMessages;
