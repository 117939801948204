import * as React from 'react';

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';
import PhoneField from './PhoneField';

const filters = [<SearchInput source="q" alwaysOn />];

const CustomersList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      sort={{ field: 'signUpDate', order: 'DESC' }}
      perPage={25}
      title={translate('pos.menu.customers')}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <TextField source="name" label="Имя" />
          <TextField source="email" label="Email" />
          <TextField source="companyName" label="Название компании" />
          <TextField source="city" label="Город" />
          <PhoneField source="phone" label="Телефон" />
          <ReferenceField
            label="Категория цен"
            source="discount"
            reference="discounts"
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            source="credit"
            label="Заказано"
            options={{
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }}
          />
          <NumberField
            source="debit"
            label="Оплачено"
            options={{
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }}
          />
          <NumberField
            source="balance"
            label="Баланс"
            options={{
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }}
          />
          <NumberField
            source="bonuses"
            label="Бонусы"
            options={{
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }}
          />
          {/* <NumberField
            source="balanceConverted"
            label="Баланс, zł"
            options={{
              style: 'currency',
              currency: 'PLN',
              maximumFractionDigits: 2,
            }}
          /> */}
          <TextField source="userCertificate" label="Сертификат" />
          <DateField source="signUpDate" label="Дата регистрации" />
        </Datagrid>
      )}
    </List>
  );
};

export default CustomersList;
