export const countriesWithRegions = [
  {
    "country": {
      "value": "holland",
      "label": "Holland"
    },
    "regions": [
      {
        "label": "Connacht",
        "value": "connacht"
      },
      {
        "label": "Leinster",
        "value": "leinster"
      },
      {
        "label": "Munster",
        "value": "munster"
      },
      {
        "label": "Ulster",
        "value": "ulster"
      }
    ]
  },
  {
    "country": {
      "value": "finland",
      "label": "Finland"
    },
    "regions": [
      {
        "label": "Uusimaa",
        "value": "uusimaa"
      },
      {
        "label": "Varsinais-Suomi",
        "value": "varsinais-suomi"
      },
      {
        "label": "Satakunta",
        "value": "satakunta"
      },
      {
        "label": "Pirkanmaa",
        "value": "pirkanmaa"
      },
      {
        "label": "Kanta-Häme",
        "value": "kanta-hame"
      },
      {
        "label": "Päijät-Häme",
        "value": "paijat-hame"
      },
      {
        "label": "Kymenlaakso",
        "value": "kymenlaakso"
      },
      {
        "label": "Etelä-Karjala",
        "value": "etela-karjala"
      },
      {
        "label": "Etelä-Savo",
        "value": "etela-savo"
      },
      {
        "label": "Pohjois-Savo",
        "value": "pohjois-savo"
      },
      {
        "label": "Pohjois-Karjala",
        "value": "pohjois-karjala"
      },
      {
        "label": "Keski-Suomi",
        "value": "keski-suomi"
      },
      {
        "label": "Etelä-Pohjanmaa",
        "value": "etela-pohjanmaa"
      },
      {
        "label": "Pohjanmaa",
        "value": "pohjanmaa"
      },
      {
        "label": "Pohjois-Pohjanmaa",
        "value": "pohjois-pohjanmaa"
      },
      {
        "label": "Kainuu",
        "value": "kainuu"
      },
      {
        "label": "Lappi",
        "value": "lappi"
      }
    ]
  },
  {
    "country": {
      "value": "estonia",
      "label": "Estonia"
    },
    "regions": [
      {
        "label": "Harjumaa",
        "value": "harjumaa"
      },
      {
        "label": "Hiiumaa",
        "value": "hiiumaa"
      },
      {
        "label": "Ida-Virumaa",
        "value": "ida-virumaa"
      },
      {
        "label": "Jõgevamaa",
        "value": "jogevamaa"
      },
      {
        "label": "Järvamaa",
        "value": "jarvamaa"
      },
      {
        "label": "Läänemere",
        "value": "laanemere"
      },
      {
        "label": "Põlvamaa",
        "value": "polvamaa"
      },
      {
        "label": "Pärnumaa",
        "value": "parnumaa"
      },
      {
        "label": "Raplamaa",
        "value": "raplamaa"
      },
      {
        "label": "Saaremaa",
        "value": "saaremaa"
      },
      {
        "label": "Tartumaa",
        "value": "tartumaa"
      },
      {
        "label": "Valgamaa",
        "value": "valgamaa"
      },
      {
        "label": "Viljandimaa",
        "value": "viljandimaa"
      },
      {
        "label": "Võrumaa",
        "value": "vorumaa"
      }
    ]
  },
  {
    "country": {
      "value": "lithuania",
      "label": "Lithuania"
    },
    "regions": [
      {
        "label": "Vilniaus apskritis",
        "value": "vilniaus-apskritis"
      },
      {
        "label": "Kauno apskritis",
        "value": "kauno-apskritis"
      },
      {
        "label": "Šiaulių apskritis",
        "value": "siauliu-apskritis"
      },
      {
        "label": "Panevėžio apskritis",
        "value": "panevezio-apskritis"
      },
      {
        "label": "Klaipėdos apskritis",
        "value": "klaipedos-apskritis"
      },
      {
        "label": "Telšių apskritis",
        "value": "telsiu-apskritis"
      },
      {
        "label": "Utenos apskritis",
        "value": "utenos-apskritis"
      },
      {
        "label": "Alytaus apskritis",
        "value": "alytaus-apskritis"
      },
      {
        "label": "Marijampolės apskritis",
        "value": "marijampoles-apskritis"
      }
    ]
  },
  {
    "country": {
      "value": "latvia",
      "label": "Latvia"
    },
    "regions": [
      {
        "label": "Rīga",
        "value": "riga"
      },
      {
        "label": "Pierīga",
        "value": "pieriga"
      },
      {
        "label": "Kurzeme",
        "value": "kurzeme"
      },
      {
        "label": "Zemgale",
        "value": "zemgale"
      },
      {
        "label": "Vidzeme",
        "value": "vidzeme"
      },
      {
        "label": "Latgale",
        "value": "latgale"
      }
    ]
  },
  {
    "country": {
      "value": "portugal",
      "label": "Portugal"
    },
    "regions": [
      {
        "label": "Nordeste",
        "value": "nordeste"
      },
      {
        "label": "Centro",
        "value": "centro"
      },
      {
        "label": "Lisboa",
        "value": "lisboa"
      },
      {
        "label": "Alentejo",
        "value": "alentejo"
      },
      {
        "label": "Algarve",
        "value": "algarve"
      },
      {
        "label": "Açores",
        "value": "acores"
      },
      {
        "label": "Madeira",
        "value": "madeira"
      }
    ]
  },
  {
    "country": {
      "value": "italy",
      "label": "Italy"
    },
    "regions": [
      {
        "label": "Piemonte",
        "value": "piemonte"
      },
      {
        "label": "Valle d'Aosta",
        "value": "valle-d-aosta"
      },
      {
        "label": "Liguria",
        "value": "liguria"
      },
      {
        "label": "Lombardia",
        "value": "lombardia"
      },
      {
        "label": "Trentino-Alto Adige",
        "value": "trentino-alto-adige"
      },
      {
        "label": "Veneto",
        "value": "veneto"
      },
      {
        "label": "Friuli Venezia Giulia",
        "value": "friuli-venezia-giulia"
      },
      {
        "label": "Emilia-Romagna",
        "value": "emilia-romagna"
      },
      {
        "label": "Toscana",
        "value": "toscana"
      },
      {
        "label": "Umbria",
        "value": "umbria"
      },
      {
        "label": "Marche",
        "value": "marche"
      },
      {
        "label": "Lazio",
        "value": "lazio"
      },
      {
        "label": "Abruzzo",
        "value": "abruzzo"
      },
      {
        "label": "Molise",
        "value": "molise"
      },
      {
        "label": "Campania",
        "value": "campania"
      },
      {
        "label": "Puglia",
        "value": "puglia"
      },
      {
        "label": "Basilicata",
        "value": "basilicata"
      },
      {
        "label": "Calabria",
        "value": "calabria"
      },
      {
        "label": "Sicilia",
        "value": "sicilia"
      },
      {
        "label": "Sardegna",
        "value": "sardegna"
      }
    ]
  },
  {
    "country": {
      "value": "netherlands",
      "label": "Netherlands"
    },
    "regions": [
      {
        "label": "Drenthe",
        "value": "drenthe"
      },
      {
        "label": "Flevoland",
        "value": "flevoland"
      },
      {
        "label": "Friesland",
        "value": "friesland"
      },
      {
        "label": "Gelderland",
        "value": "gelderland"
      },
      {
        "label": "Groningen",
        "value": "groningen"
      },
      {
        "label": "Limburg",
        "value": "limburg"
      },
      {
        "label": "Noord-Brabant",
        "value": "noord-brabant"
      },
      {
        "label": "Noord-Holland",
        "value": "noord-holland"
      },
      {
        "label": "Overijssel",
        "value": "overijssel"
      },
      {
        "label": "Utrecht",
        "value": "utrecht"
      },
      {
        "label": "Zeeland",
        "value": "zeeland"
      },
      {
        "label": "Zuid-Holland",
        "value": "zuid-holland"
      }
    ]
  },
  {
    "country": {
      "value": "sweden",
      "label": "Sweden"
    },
    "regions": [
      {
        "label": "Stockholms län",
        "value": "stockholms-lan"
      },
      {
        "label": "Uppsala län",
        "value": "uppsala-lan"
      },
      {
        "label": "Södermanlands län",
        "value": "sodermanlands-lan"
      },
      {
        "label": "Östergötlands län",
        "value": "ostergotlands-lan"
      },
      {
        "label": "Jönköpings län",
        "value": "jonkopings-lan"
      },
      {
        "label": "Kronobergs län",
        "value": "kronobergs-lan"
      },
      {
        "label": "Kalmar län",
        "value": "kalmar-lan"
      },
      {
        "label": "Gotlands län",
        "value": "gotlands-lan"
      },
      {
        "label": "Blekinge län",
        "value": "blekinge-lan"
      },
      {
        "label": "Skåne län",
        "value": "skane-lan"
      },
      {
        "label": "Hallands län",
        "value": "hallands-lan"
      },
      {
        "label": "Västra Götalands län",
        "value": "vastra-gotalands-lan"
      },
      {
        "label": "Värmlands län",
        "value": "varmlands-lan"
      },
      {
        "label": "Örebro län",
        "value": "orebro-lan"
      },
      {
        "label": "Västmanlands län",
        "value": "vastmanlands-lan"
      },
      {
        "label": "Dalarna län",
        "value": "dalarna-lan"
      },
      {
        "label": "Gävleborgs län",
        "value": "gavleborgs-lan"
      },
      {
        "label": "Hälsinglands län",
        "value": "halsinglands-lan"
      },
      {
        "label": "Jämtlands län",
        "value": "jamtlands-lan"
      },
      {
        "label": "Västernorrlands län",
        "value": "vasternorrlands-lan"
      },
      {
        "label": "Västerbottens län",
        "value": "vasterbottens-lan"
      },
      {
        "label": "Norrbottens län",
        "value": "norrbottens-lan"
      }
    ]
  },
  {
    "country": {
      "value": "poland",
      "label": "Poland"
    },
    "regions": [
      {
        "label": "Maszowskie",
        "value": "maszowskie"
      },
      {
        "label": "Kujawsko-Pomorskie",
        "value": "kujawsko-pomorskie"
      },
      {
        "label": "Lubuskie",
        "value": "lubuskie"
      },
      {
        "label": "Lubelskie",
        "value": "lubelskie"
      },
      {
        "label": "Łódzkie",
        "value": "lodzkie"
      },
      {
        "label": "Małopolskie",
        "value": "malopolskie"
      },
      {
        "label": "Mazowieckie",
        "value": "mazowieckie"
      },
      {
        "label": "Opolskie",
        "value": "opolskie"
      },
      {
        "label": "Podkarpackie",
        "value": "podkarpackie"
      },
      {
        "label": "Podlaskie",
        "value": "podlaskie"
      },
      {
        "label": "Pomorskie",
        "value": "pomorskie"
      },
      {
        "label": "Śląskie",
        "value": "slaskie"
      },
      {
        "label": "Świętokrzyskie",
        "value": "swietokrzyskie"
      },
      {
        "label": "Warmińsko-Mazurskie",
        "value": "warminsko-mazurskie"
      },
      {
        "label": "Wielkopolskie",
        "value": "wielkopolskie"
      },
      {
        "label": "Zachodniopomorskie",
        "value": "zachodniopomorskie"
      }
    ]
  },
  {
    "country": {
      "value": "germany",
      "label": "Germany"
    },
    "regions": [
      {
        "label": "Baden-Württemberg",
        "value": "baden-wurttemberg"
      },
      {
        "label": "Bayern",
        "value": "bayern"
      },
      {
        "label": "Berlin",
        "value": "berlin"
      },
      {
        "label": "Brandenburg",
        "value": "brandenburg"
      },
      {
        "label": "Bremen",
        "value": "bremen"
      },
      {
        "label": "Hamburg",
        "value": "hamburg"
      },
      {
        "label": "Hessen",
        "value": "hessen"
      },
      {
        "label": "Mecklenburg-Vorpommern",
        "value": "mecklenburg-vorpommern"
      },
      {
        "label": "Niedersachsen",
        "value": "niedersachsen"
      },
      {
        "label": "Nordrhein-Westfalen",
        "value": "nordrhein-westfalen"
      },
      {
        "label": "Rheinland-Pfalz",
        "value": "rheinland-pfalz"
      },
      {
        "label": "Saarland",
        "value": "saarland"
      },
      {
        "label": "Sachsen",
        "value": "sachsen"
      },
      {
        "label": "Sachsen-Anhalt",
        "value": "sachsen-anhalt"
      },
      {
        "label": "Schleswig-Holstein",
        "value": "schleswig-holstein"
      },
      {
        "label": "Thüringen",
        "value": "thuringen"
      }
    ]
  },
  {
    "country": {
      "value": "spain",
      "label": "Spain"
    },
    "regions": [
      {
        "label": "Andalucía",
        "value": "andalucia"
      },
      {
        "label": "Aragón",
        "value": "aragon"
      },
      {
        "label": "Asturias",
        "value": "asturias"
      },
      {
        "label": "Islas Baleares",
        "value": "islas-baleares"
      },
      {
        "label": "Canarias",
        "value": "canarias"
      },
      {
        "label": "Cantabria",
        "value": "cantabria"
      },
      {
        "label": "Castilla-La Mancha",
        "value": "castilla-la-mancha"
      },
      {
        "label": "Castilla y León",
        "value": "castilla-y-leon"
      },
      {
        "label": "Cataluña",
        "value": "cataluna"
      },
      {
        "label": "Extremadura",
        "value": "extremadura"
      },
      {
        "label": "Galicia",
        "value": "galicia"
      },
      {
        "label": "Madrid",
        "value": "madrid"
      },
      {
        "label": "Murcia",
        "value": "murcia"
      },
      {
        "label": "Navarra",
        "value": "navarra"
      },
      {
        "label": "La Rioja",
        "value": "la-rioja"
      },
      {
        "label": "País Vasco",
        "value": "pais-vasco"
      },
      {
        "label": "Ceuta",
        "value": "ceuta"
      },
      {
        "label": "Melilla",
        "value": "melilla"
      }
    ]
  },
  {
    "country": {
      "value": "france",
      "label": "France"
    },
    "regions": [
      {
        "label": "Auvergne-Rhône-Alpes",
        "value": "auvergne-rhone-alpes"
      },
      {
        "label": "Bourgogne-Franche-Comté",
        "value": "bourgogne-franche-comte"
      },
      {
        "label": "Bretagne",
        "value": "bretagne"
      },
      {
        "label": "Centre-Val de Loire",
        "value": "centre-val-de-loire"
      },
      {
        "label": "Corse",
        "value": "corse"
      },
      {
        "label": "Grand Est",
        "value": "grand-est"
      },
      {
        "label": "Hauts-de-France",
        "value": "hauts-de-france"
      },
      {
        "label": "Île-de-France",
        "value": "ile-de-france"
      },
      {
        "label": "Normandie",
        "value": "normandie"
      },
      {
        "label": "Nouvelle-Aquitaine",
        "value": "nouvelle-aquitaine"
      },
      {
        "label": "Occitanie",
        "value": "occitanie"
      },
      {
        "label": "Pays de la Loire",
        "value": "pays-de-la-loire"
      },
      {
        "label": "Provence-Alpes-Côte d'Azur",
        "value": "provence-alpes-cote-d'azur"
      }
    ]
  },
  {
    "country": {
      "value": "norway",
      "label": "Norway"
    },
    "regions": [
      {
        "label": "Hovedstaden",
        "value": "hovedstaden"
      },
      {
        "label": "Sjælland",
        "value": "sjaelland"
      },
      {
        "label": "Syddanmark",
        "value": "syddanmark"
      },
      {
        "label": "Midtjylland",
        "value": "midtjylland"
      },
      {
        "label": "Nordjylland",
        "value": "nordjylland"
      }
    ]
  },
  {
    "country": {
      "value": "hungary",
      "label": "Hungary"
    },
    "regions": [
      {
        "label": "Budapest",
        "value": "budapest"
      },
      {
        "label": "Pest",
        "value": "pest"
      },
      {
        "label": "Győr-Moson-Sopron",
        "value": "gyor-moson-sopron"
      },
      {
        "label": "Vas",
        "value": "vas"
      },
      {
        "label": "Zala",
        "value": "zala"
      },
      {
        "label": "Komárom-Esztergom",
        "value": "komarom-esztergom"
      },
      {
        "label": "Fejér",
        "value": "fejer"
      },
      {
        "label": "Veszprém",
        "value": "veszprem"
      },
      {
        "label": "Somogy",
        "value": "somogy"
      },
      {
        "label": "Tolna",
        "value": "tolna"
      },
      {
        "label": "Baranya",
        "value": "baranya"
      },
      {
        "label": "Bács-Kiskun",
        "value": "bacs-kiskun"
      },
      {
        "label": "Jász-Nagykun-Szolnok",
        "value": "jasz-nagykun-szolnok"
      },
      {
        "label": "Hajdú-Bihar",
        "value": "hajdu-bihar"
      },
      {
        "label": "Szabolcs-Szatmár-Bereg",
        "value": "szabolcs-szatmar-bereg"
      },
      {
        "label": "Heves",
        "value": "heves"
      },
      {
        "label": "Nógrád",
        "value": "nograd"
      }
    ]
  }
]
