import * as React from 'react';

import {
  Datagrid,
  DateField,
  List,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const filters = [
  <SearchInput source="q" alwaysOn />,
  <SelectInput
    source="section"
    label={'Секция'}
    choices={[
      {
        id: 'instructions',
        name: 'Инструкции',
      },
      {
        id: 'catalogs',
        name: 'Каталоги',
      },
    ]}
  />,
];

const CatalogsPdfList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={false}
      filters={!isXsmall ? filters : undefined}
      title={translate('pos.menu.catalogs_pdf')}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.catalogs_pdf.fields.name')}
          />
          <SelectField
            source="section"
            label={translate('resources.catalogs_pdf.fields.section')}
            choices={[
              {
                id: 'instructions',
                name: translate(
                  'resources.catalogs_pdf.fields.sections.instructions'
                ),
              },
              {
                id: 'catalogs',
                name: translate(
                  'resources.catalogs_pdf.fields.sections.catalogs'
                ),
              },
            ]}
          />
          <DateField
            source="date"
            label={translate('resources.catalogs_pdf.fields.date')}
          />
          <TextField
            source="order"
            label={translate('resources.catalogs_pdf.fields.order')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default CatalogsPdfList;
