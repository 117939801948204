import * as React from 'react';

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
} from 'react-admin';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import Box from '@mui/material/Box';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SubMenu from './SubMenu';
import catalogspdf from '../resources/catalogsPdf';
import categories from '../resources/catalog/categories';
import contacts from '../resources/contacts';
import customers from '../resources/customers';
import discounts from '../resources/discounts';
import errorcodes from '../resources/errorCodes';
import items from '../resources/catalog/items';
import marketingCategories from '../resources/marketingMaterials/categories';
import marketingItems from '../resources/marketingMaterials/items';
import notifications from '../resources/notifications';
import orders from '../resources/orders';
import paymentReports from '../resources/paymentReports';
import pendingRequests from '../resources/pendingRequests';
import price from '../resources/price';
import rates from '../resources/rates';
import recurringNotifications from '../resources/recurringNotifications';
import sections from '../resources/catalog/sections';
import series from '../resources/catalog/series';
import settings from '../resources/settings';
import { useState } from 'react';
import warranties from '../resources/warranties';

type MenuName = 'menuCatalog' | 'menuMarketingMaterials';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: false,
    menuMarketingMaterials: false,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <MenuItemLink
        to="/customers"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.customers`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<customers.icon />}
      />
      <MenuItemLink
        to="/pending-requests"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.pending_requests`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<pendingRequests.icon />}
      />
      <MenuItemLink
        to="/warranties"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.warranties`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<warranties.icon />}
      />
      <MenuItemLink
        to="/orders"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.orders`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<orders.icon />}
      />
      <MenuItemLink
        to="/rates"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.rates`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<rates.icon />}
      />
      <MenuItemLink
        to="/discounts"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.discounts`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<discounts.icon />}
      />
      <MenuItemLink
        to="/errorcodes"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.errorcodes`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<errorcodes.icon />}
      />
      <MenuItemLink
        to="/catalogs-pdf"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.catalogs_pdf`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<catalogspdf.icon />}
      />
      <MenuItemLink
        to="/notifications"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.notifications`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<notifications.icon />}
      />
      <MenuItemLink
        to="/recurring-notifications"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.recurring_notifications`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<recurringNotifications.icon />}
      />
      <MenuItemLink
        to="/contacts"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.contacts`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<contacts.icon />}
      />
      <MenuItemLink
        to="/price"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.price`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<price.icon />}
      />
      <MenuItemLink
        to="/settings"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.settings`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<settings.icon />}
      />
      <MenuItemLink
        to="/payment-report"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.payment_reports`, {
          smart_count: 2,
        })}
        dense={dense}
        leftIcon={<paymentReports.icon />}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuCatalog')}
        isOpen={state.menuCatalog}
        name="pos.menu.catalog"
        icon={<AcUnitIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/sections"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.sections`, {
            smart_count: 2,
          })}
          dense={dense}
          leftIcon={<sections.icon />}
        />
        <MenuItemLink
          to="/categories"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.categories`, {
            smart_count: 2,
          })}
          dense={dense}
          leftIcon={<categories.icon />}
        />
        <MenuItemLink
          to="/series"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.series`, {
            smart_count: 2,
          })}
          dense={dense}
          leftIcon={<series.icon />}
        />
        <MenuItemLink
          to="/items"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.items`, {
            smart_count: 2,
          })}
          dense={dense}
          leftIcon={<items.icon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuMarketingMaterials')}
        isOpen={state.menuMarketingMaterials}
        name="pos.menu.marketing_materials.name"
        icon={<MenuBookIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/marketing-categories"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.marketing_materials.categories`, {
            smart_count: 2,
          })}
          dense={dense}
          leftIcon={<marketingCategories.icon />}
        />
        <MenuItemLink
          to="/marketing-items"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.marketing_materials.items`, {
            smart_count: 2,
          })}
          dense={dense}
          leftIcon={<marketingItems.icon />}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
