import * as React from 'react';

import { Item, Order } from '../../types';
import { Link, useGetMany, useRecordContext, useTranslate } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const Basket = () => {
  const record = useRecordContext<Order>();
  const translate = useTranslate();

  const productIds = record ? record.items.map((item) => item.id) : [];

  const { isLoading, data: products } = useGetMany<Item>(
    'items',
    { ids: productIds },
    { enabled: !!record }
  );

  const productsById = products
    ? products.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {} as any)
    : {};

  if (isLoading || !record || !products) return null;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.reference')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            {translate('resources.commands.fields.items.unit_price_usd')}
          </TableCell>
          {/* <TableCell sx={{ textAlign: 'right' }}>
            {translate('resources.commands.fields.items.unit_price')}
          </TableCell> */}
          <TableCell sx={{ textAlign: 'right' }}>
            {translate('resources.commands.fields.items.rate')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            {translate('resources.commands.fields.items.quantity')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            {translate('resources.commands.fields.items.summInUSD')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.items.map((item: any) => (
          <TableRow key={item.id}>
            <TableCell>
              <Link to={`/items/${item.id}`}>{productsById[item.id].name}</Link>
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              {/* {products[item.id].price.toLocaleString(undefined, { */}
              {item.price.toLocaleString(undefined, {
                style: 'currency',
                currency: 'EUR',
              })}
            </TableCell>
            {/* <TableCell sx={{ textAlign: 'right' }}> */}
            {/* {products[item.id].price.toLocaleString(undefined, { */}
            {/* {(item.price * item.rate).toLocaleString(undefined, {
                style: 'currency',
                currency: 'PLN',
              })}
            </TableCell> */}
            <TableCell sx={{ textAlign: 'right' }}>{item.rate}</TableCell>
            <TableCell sx={{ textAlign: 'right' }}>{item.quantity}</TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              {/* {(products[item.id].price * item.quantity).toLocaleString( */}
              {(item.price * item.quantity).toLocaleString(undefined, {
                style: 'currency',
                currency: 'EUR',
              })}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Basket;
