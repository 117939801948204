import * as React from 'react';

import {
  BooleanField,
  BooleanInput,
  Edit,
  FormDataConsumer,
  Labeled,
  NumberField,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  required,
} from 'react-admin';

import FullNameField from './FullNameField';
import { InputAdornment } from '@mui/material';
import { countriesWithRegions } from './countries';

const validatePassword = [minLength(6)];

const countriesList = countriesWithRegions.map((c) => ({
  id: c.country.value,
  name: c.country.label,
})).sort((a, b) => a.name.localeCompare(b.name));

const CustomerEdit = () => {
  return (
    <Edit title={<CustomerTitle />}>
      <SimpleForm>
        <TextInput label="Имя" source="name" validate={[required()]} />
        <TextInput label="Email" source="email" />
        <TextInput label="Название компании" source="companyName" />
        <SelectInput label="Страна" source="country" choices={countriesList} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const regionsList = countriesWithRegions.find(item => item.country.value === formData.country);
            const regions = regionsList?.regions ?? [];
            return (
              <SelectInput
                label="Регион"
                source="region"
                optionText="label"
                optionValue="value"
                choices={regions} />
            );
          }}
        </FormDataConsumer>
        
        <TextInput label="Город" source="city" />
        <TextInput label="F-Gas Certificate №" source="fgascertificate" />
        <BooleanInput label="Верифицирован (Разрешить создание гарантий)" source="qualifiedEngineer" />
        <Labeled label={'Обработка верификации'}>
          <BooleanField
            source="pendingVerification"
            label={'Обработка верификации'}
          />
        </Labeled>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <ReferenceInput
                label="Категория цен"
                source="discount"
                reference="discounts"
                validate={[required()]}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
        <TextInput
          label="Номер телефона"
          source="phone"
          validate={[required(), maxLength(12), minLength(10)]}
          inputProps={{
            maxLength: 12,
          }}
        />
        <NumberInput
          label="Заказано"
          source="credit"
          max={100000000}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(100000000)]}
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
        <Labeled label="Оплачено">
          <NumberField
            source="debit"
            options={{
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }}
          />
        </Labeled>
        {/* <NumberInput
          label="Оплачено"
          source="debit"
          max={100000000}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(100000000)]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">€</InputAdornment>
            ),
          }}
        /> */}
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <NumberInput
              disabled={formData.initialBonusApplied}
              label="Приветственный бонус"
              source="initialBonus"
              max={1000}
              min={0}
              format={(v) => parseFloat(v)}
              parse={(v) => v.toString()}
              defaultValue={0}
              validate={[number(), minValue(0), maxValue(1000)]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          )}
        </FormDataConsumer>
        <NumberInput
          label="Бонусов"
          source="bonuses"
          max={10000}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(10000)]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">€</InputAdornment>
            ),
          }}
        />
        <BooleanInput label="Изменить пароль" source="chPass" />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.chPass && (
              <Labeled
                label={`Введите новый пароль, затем отправьте его клиенту вручную. Пароль будет доступен для просмотра только 1 раз`}
              >
                <PasswordInput
                  label="Введите новый пароль"
                  source="password"
                  initiallyVisible
                  disabled={!formData.chPass}
                  validate={validatePassword}
                />
              </Labeled>
            )
          }
        </FormDataConsumer>
        <TextInput
          label="Реферальный код (сгенерированный)"
          source="referralCode"
          disabled
          style={{width: 220}}
        />
        <TextInput
          label="Реферальный код (полученный)"
          source="referralCodeReceived"
          disabled
          style={{width: 220}}
        />
        <TextInput
          label="Сертификат пользователя"
          source="userCertificate"
          // disabled
          style={{width: 220}}
        />
      </SimpleForm>
    </Edit>
  );
};

const CustomerTitle = () => (
  <FullNameField size="32" sx={{ margin: '5px 0' }} />
);

export default CustomerEdit;
