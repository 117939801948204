import PendingRequestsCreate from './PendingRequestsCreate';
import PendingRequestsEdit from './PendingRequestsEdit';
import PendingRequestsIcon from '@mui/icons-material/HourglassBottom';
import PendingRequestsList from './PendingRequestsList';

export default {
  list: PendingRequestsList,
  edit: PendingRequestsEdit,
  create: PendingRequestsCreate,
  icon: PendingRequestsIcon,
};
