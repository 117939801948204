import { useEffect } from 'react';

const ResetPasswordRedirectPage = () => {
  // Get the current URL
  const url = window.location.href;

  // Extract the part after the hash (#)
  const hash = url.split('#')[1];

  // Create a new URL object from the hash part
  const urlObj = new URL('http://localhost:3000/' + hash);

  // Get the value of the 'id' parameter
  const id = urlObj.searchParams.get('id');

  useEffect(() => {
    // Define your deep link URL
    const deepLink = `senseieu://reset-password/${id}`;
    // const deepLink = "https://google.com/";

    // Redirect to the deep link after a short delay
    setTimeout(function () {
      // window.location.href = deepLink;
      window.location.replace(deepLink);
    }, 100); // 100ms delay before redirecting
  }, []);
  return (
    <div>
      <p>Redirecting to the app...</p>
      <p>
        If you are not redirected,{' '}
        <a href={`senseieu://reset-password/${id}`}>click here</a>.
      </p>
    </div>
  );
};

export default ResetPasswordRedirectPage;
