import * as React from 'react';

import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Divider, InputAdornment } from '@mui/material';
import { ImagesField, images } from '../../../components/ImagesField';

import { IItem } from '../../../types';
import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';
import specificationsCaptions from './specificationsCaptions';

const Title = () => {
  const record = useRecordContext<IItem>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.items.editText')} {record ? `"${record.name}"` : ''}
    </span>
  );
};

const ItemsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <TabbedForm>
        <FormTab label={translate('resources.items.tabs.main')}>
          <ReferenceInput
            source="section"
            reference="sections"
            validate={[required()]}
            perPage={1000}
          >
            <SelectInput
              optionText="name"
              label="Раздел"
              validate={[required()]}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.section ? (
                <ReferenceInput
                  source="category"
                  reference="categories"
                  perPage={1000}
                  filter={{ section_q: formData.section }}
                  validate={[required()]}
                  {...rest}
                >
                  <SelectInput
                    optionText="name"
                    label="Категория"
                    validate={[required()]}
                  />
                </ReferenceInput>
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.category ? (
                <ReferenceInput
                  source="serie"
                  reference="series"
                  perPage={1000}
                  filter={{ category_q: formData.category }}
                  validate={[required()]}
                  {...rest}
                >
                  <SelectInput
                    optionText="name"
                    label="Серия"
                    validate={[required()]}
                  />
                </ReferenceInput>
              ) : null
            }
          </FormDataConsumer>
          <TextInput
            source="name"
            label="Название товара"
            validate={[required()]}
          />
          {/* <MyTranslatableInputs groupKey="name">
            <TextInput
              source="nameTranslated"
              label={'Название товара'}
              validate={[required()]}
              fullWidth
            />
          </MyTranslatableInputs> */}
          <TextInput source="main.cool" label="Охлаждение" defaultValue="" />
          <TextInput source="main.heat" label="Обогрев" defaultValue="" />
          <NumberInput
            source="price"
            label="Цена, розн."
            format={(v) => parseFloat(v)}
            parse={(v) => v.toString().split('.')[0]}
            defaultValue={0}
            validate={[required()]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="order"
            label="Порядок"
            min={1}
            max={99}
            step={1}
            validate={[required()]}
            style={{ width: 160 }}
          />
          <Divider />
          <p>Склад</p>
          <NumberInput
            source="stock1"
            label="Наличие"
            format={(v) => parseFloat(v)}
            parse={(v) => v.toString().split('.')[0]}
            min={0}
            step={1}
            defaultValue={0}
            validate={[required()]}
          />
        </FormTab>
        <FormTab
          label={translate('resources.items.tabs.description')}
          path="description"
        >
          <BooleanInput
            fullWidth
            label="Использовать описание серии"
            source="useGlobalDescription"
            defaultValue={true}
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) =>
              !formData.useGlobalDescription && (
                <MyTranslatableInputs groupKey="description">
                  <ArrayInput
                    source="description_translated"
                    label={translate('resources.items.tabs.description')}
                  >
                    <SimpleFormIterator fullWidth>
                      <TextInput
                        fullWidth
                        source="title"
                        label={translate('resources.items.fields.title')}
                      />
                      <TextInput
                        fullWidth
                        source="text"
                        label={translate('resources.items.fields.text')}
                        multiline
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </MyTranslatableInputs>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label={translate('resources.items.tabs.advantages')}
          path="advantages"
        >
          <BooleanInput
            fullWidth
            label="Использовать преимущества серии"
            source="useGlobalAdvantages"
            defaultValue={true}
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) =>
              !formData.useGlobalAdvantages && (
                <>
                  <ImagesField />
                  <MyTranslatableInputs groupKey="advantages">
                    <ArrayInput
                      source={'advantages_translated'}
                      label={translate('resources.items.tabs.advantages')}
                    >
                      <SimpleFormIterator fullWidth>
                        {/* <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        fullWidth
                        source="image"
                        choices={images}
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <TextInput fullWidth source="title" />
                    </Box>
                  </Box> */}
                        <SelectInput
                          source="image"
                          label={translate('resources.items.fields.image')}
                          choices={images}
                        />
                        <TextInput
                          fullWidth
                          source="title"
                          label={translate('resources.items.fields.title')}
                        />
                        <TextInput
                          fullWidth
                          source="text"
                          label={translate('resources.items.fields.text')}
                          multiline
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </MyTranslatableInputs>
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab
          // disabled
          label={translate('resources.items.tabs.specifications.title')}
          path="specifications"
        >
          <SelectArrayInput
            fullWidth
            label="Выберите разделы"
            source="specifications_translated.captions"
            choices={specificationsCaptions}
          />
          <FormDataConsumer fullWidth>
            {({ formData, ...rest }: { formData: IItem }) => {
              if (
                formData?.specifications_translated?.captions &&
                formData.specifications_translated.captions.length
              ) {
                return (
                  <MyTranslatableInputs groupKey="specifications" singleLocale>
                    {formData.specifications_translated.captions.map(
                      (item, index) => {
                        return (
                          <ArrayInput
                            key={index}
                            source={`specifications_translated.items.${item}`}
                            label={translate(
                              `resources.items.tabs.specifications.${item}`
                            )}
                            {...rest}
                          >
                            <SimpleFormIterator fullWidth>
                              <TextInput fullWidth source="key" label="Ключ" />
                              <TextInput
                                fullWidth
                                source="value"
                                label="Значение"
                              />
                            </SimpleFormIterator>
                          </ArrayInput>
                        );
                      }
                    )}
                  </MyTranslatableInputs>
                );
              }
              return null;
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ItemsEdit;
