import * as React from 'react';

import {
  BooleanInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  Labeled,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Divider } from '@mui/material';
import { IPaymentReport } from '../../types';

const Title = () => {
  const record = useRecordContext<IPaymentReport>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.pending_requests.name')}{' '}
      {record ? `"${record._id}"` : ''}
    </span>
  );
};

// const EditToolbar = () => (
//   <Toolbar>
//     <SaveButton />
//   </Toolbar>
// );

const PendingRequestsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <DateTimeInput
          source="date"
          label={translate('resources.pending_requests.fields.date')}
          validate={[required()]}
          disabled
        />
        <ReferenceInput
          source="clientId"
          reference="customers"
          allowEmpty
          perPage={1000}
        >
          <SelectInput
            disabled
            label={translate('resources.pending_requests.fields.clientId')}
            optionText={(record) =>
              record
                ? `${record.name} ${
                    record.companyName && '(' + record.companyName + ')'
                  } - ${record.phone}`
                : ''
            }
            fullWidth
          />
        </ReferenceInput>
        <TextInput
          source="name"
          disabled
          label={translate('resources.pending_requests.fields.name')}
        />
        <TextInput
          source="companyName"
          disabled
          label={translate('resources.pending_requests.fields.companyName')}
        />
        <TextInput
          source="country"
          disabled
          label={translate('resources.pending_requests.fields.country')}
        />
        <TextInput
          source="region"
          disabled
          label={translate('resources.pending_requests.fields.region')}
        />
        <TextInput
          source="city"
          disabled
          label={translate('resources.pending_requests.fields.city')}
        />
        <TextInput
          source="fgascertificate"
          disabled
          label={translate('resources.pending_requests.fields.fgascertificate')}
        />
        <NullableBooleanInput
          style={{ width: 160 }}
          source="verify"
          label={translate('resources.pending_requests.fields.verify')}
        />
      </SimpleForm>
    </Edit>
  );
};

export default PendingRequestsEdit;
