import { Identifier, RaRecord } from 'react-admin';

export type ThemeName = 'light' | 'dark';

export interface Category extends RaRecord {
  name: string;
}

export interface Product extends RaRecord {
  category_id: Identifier;
  description: string;
  height: number;
  image: string;
  price: number;
  reference: string;
  stock: number;
  thumbnail: string;
  width: number;
}

export interface DeviceId {
  deviceId: string;
  deviceToken: string;
}

export interface CustomerAddress {
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  phoneNumber: string;
  city: string;
  cityId: string;
  post: string;
  postNumber: string;
  branchId: string;
  type: string;
  courierAddress: string;
  province: string;
  zipCode: string;
  contactAddress: string;
  contactAddress2: string;
  street: string;
  streetNumber: string;
}

export interface Customer extends RaRecord {
  phone: string;
  email: string;
  name: string;
  companyName: string;
  city: string;
  fgascertificate: string;
  qualifiedEngineer: boolean;
  avatar: string;
  password: string;
  discount: string;
  initialBonus: number;
  initialBonusApplied: boolean;
  bonuses: number;
  signUpDate: Date;
  disabled: boolean;
  deviceIds: DeviceId[];
  address: CustomerAddress;
  addresses: CustomerAddress[];
  balance: number;
  debit: number;
  credit: number;
}

export type OrderStatus = 'ordered' | 'delivered' | 'cancelled';

export interface Order extends RaRecord {
  orderId: string;
  clientId: string;
  shippingMethod: string;
  shippingInfo: ShippingInfo;
  status: OrderStatus;
  items: BasketItem[];
  date: Date;
  paymentDate: Date;
  paymentSumm: number;
  paymentMethod: string;
  bonuses: number;
  bonusAdded: boolean;
  nextBonus: number;
  totalSumm: number;
  trackNumber: string;
  returned: boolean;
  payment: boolean;
}

export interface BasketItem {
  id: Identifier;
  price: number;
  quantity: number;
  name: string;
  serieName: string;
  image: string;
  rate: string;
}

export interface ShippingInfo {
  firstName: string;
  lastName: string;
  patronymic: string;
  phoneNumber: string;
  city: string;
  cityId: string;
  post: string;
  branchId: string;
  postNumber: string;
  type: string;
  courierAddress: string;
  province: string;
  zipCode: string;
  contactAddress: string;
  contactAddress2: string;
  street: string;
  streetNumber: string;
}

// export interface Invoice extends RaRecord { }

// export type ReviewStatus = 'accepted' | 'pending' | 'rejected';

// export interface Review extends RaRecord {
//   date: Date;
//   status: ReviewStatus;
//   customer_id: Identifier;
//   product_id: Identifier;
// }

export interface Item extends RaRecord {
  [key: string]: any;
}

export interface IExchangeRateItem extends RaRecord {
  name: string;
  cashRate: string;
  cashlessRate: string;
  date: Date;
}

export interface IExchangeRate extends IExchangeRateItem {
  history: IExchangeRateItem[];
}

export interface IDiscounts extends RaRecord {
  name: string;
  discount: string;
  allowPayments: boolean;
  allowBonus: boolean;
  bonusPeriod: number;
  bonusesAmount: number;
  date: Date;
  allowWarranty: boolean;
}

export interface IErrorCode extends RaRecord {
  name: string;
  section: string;
  category: string;
  serie: string;
  description: string;
  order: string;
  date: Date;
}

export interface ICatalogsPdf extends RaRecord {
  name: string;
  file: string;
  order: string;
  date: Date;
}

export interface INotifications extends RaRecord {
  name: string;
  notificationTitle: string;
  notificationText: string;
  text: string;
  body: string;
  image: string;
  personal: boolean;
  date: Date;
}

export interface IRecurringNotifications extends RaRecord {
  name: string;
  notificationTitle: string;
  notificationText: string;
  active: boolean;
  date: Date;
}

export interface IOffice {
  label: string;
  lat: string;
  lng: string;
}

export interface IContacts extends RaRecord {
  phoneNumber: string;
  office: IOffice;
  email: string;
  supportPhoneNumber: string;
}

export interface IPrice extends RaRecord {
  name: string;
  topic: string;
  description: string;
  file: string;
  day: string;
  recipientType: string;
  active: boolean;
  date: Date;
}

export interface ISettings extends RaRecord {
  id: string;
  name: string;
  maintenance: string;
  usePayments: boolean;
  useBonuses: boolean;
  allowSwitchCurrency: boolean;
  date: Date;
}

export interface IPaymentReport extends RaRecord {
  clientId: string;
  orderId: string;
  paymentMethod: string;
  paymentValue: number;
  paymentDate: Date;
  processed: boolean;
  processedDate: Date;
  type: string;
  date: Date;
  changedDate: Date;
  changedUserId: string;
}

export interface IUser extends RaRecord {
  phone: string;
  email: string;
  name: string;
  password: number;
  role: string;
  signUpDate: Date;
}

export enum IWarrantyEquipmentType {
  AirConditioner = 'air_conditioner',
  HeatPump = 'heat_pump',
}

export enum IWarrantyStatus {
  Active = 'active',
  Finished = 'finished',
}

export interface IWarranty extends RaRecord {
  clientId: string;
  equipmentType: IWarrantyEquipmentType;
  code: string;
  code2: string;
  clientData: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }
  status: IWarrantyStatus;
  date: Date;
}

export interface ISection extends RaRecord {
  name: string;
  nameTranslated: {
    [key: string]: string;
  };
  currency: string;
  order: string;
  showErrorCodes: boolean;
  date: Date;
}

export interface IMarketingCategory extends RaRecord {
  name: string;
  nameTranslated: {
    [key: string]: string;
  };
  order: string;
  date: Date;
}

export interface IMarketingItem extends RaRecord {
  name: string;
  nameTranslated: {
    [key: string]: string;
  };
  file: string;
  order: string;
  date: Date;
}

export interface ICategory extends RaRecord {
  name: string;
  section: string;
  order: string;
  date: Date;
}

export interface ISerieDescriptionLong {
  title: string;
  text: string;
}

export interface ISerieAdvantages {
  title: string;
  text: string;
  image: string;
}

export interface ISpecifications {
  captions: [{ type: string }];
  items: any;
}

export interface ISerie extends RaRecord {
  section: string;
  category: string;
  name: string;
  description: string;
  image: string;
  itemImage_1: string;
  itemImage_2: string;
  itemImage_3: string;
  description_long: ISerieDescriptionLong[];
  advantages: ISerieAdvantages[];
  order: string;
  date: Date;
}

export interface IItem extends RaRecord {
  name: string;
  section: string;
  category: string;
  serie: string;
  price: string;
  main: {
    cool: string;
    heat: string;
  };
  description: ISerieDescriptionLong[];
  advantages: ISerieAdvantages[];
  specifications: ISpecifications;
  specifications_translated: {
    captions: string[];
    items: Object;
  }
  useGlobalDescription: boolean;
  useGlobalAdvantages: boolean;
  stock1: number;
  order: string;
  date: Date;
}

declare global {
  interface Window {
    restServer: any;
  }
}