import * as React from 'react';

import {
  Create,
  FileField,
  FileInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { MyTranslatableInputs } from '../../components/MyTranslatableInputs';

const CatalogsPdfCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate('resources.catalogs_pdf.fields.name')}
            validate={[required()]}
            fullWidth
          />
        </MyTranslatableInputs>
        <SelectInput
          source="section"
          label={translate('resources.catalogs_pdf.fields.section')}
          choices={[
            {
              id: 'instructions',
              name: translate(
                'resources.catalogs_pdf.fields.sections.instructions'
              ),
            },
            {
              id: 'catalogs',
              name: translate(
                'resources.catalogs_pdf.fields.sections.catalogs'
              ),
            },
          ]}
          validate={[required()]}
        />
        <FileInput
          source="image"
          label={translate('resources.catalogs_pdf.fields.image')}
          accept="application/pdf"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <NumberInput
          source="order"
          label={translate('resources.catalogs_pdf.fields.order')}
          min={1}
          max={99}
          step={1}
          defaultValue={99}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Create>
  );
};

export default CatalogsPdfCreate;
