import * as React from 'react';

import {
  ArrayInput,
  Create,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import { ImagesField, images } from '../../../components/ImagesField';

import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';

const SeriesCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <TabbedForm>
        <FormTab label={translate('resources.series.tabs.main')}>
          <ReferenceInput
            label={translate('resources.series.fields.section')}
            source="section"
            reference="sections"
            validate={[required()]}
            perPage={1000}
          >
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.section ? (
                <ReferenceInput
                  label={translate('resources.series.fields.category')}
                  source="category"
                  reference="categories"
                  perPage={1000}
                  filter={{ section_q: formData.section }}
                  validate={[required()]}
                  {...rest}
                >
                  <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>
              ) : null
            }
          </FormDataConsumer>
          {/* <TextInput
            source="name"
            label={translate('resources.series.fields.name')}
            validate={[required()]}
          />
          <TextInput
            source="description"
            label={translate('resources.series.fields.description')}
            multiline
            fullWidth
          /> */}
          <MyTranslatableInputs groupKey="name">
            <TextInput
              source="nameTranslated"
              label={translate('resources.series.fields.name')}
              validate={[required()]}
              fullWidth
            />
            <TextInput
              source="descriptionTranslated"
              label={translate('resources.series.fields.description')}
              multiline
              fullWidth
            />
          </MyTranslatableInputs>
          <ImageInput
            source="image"
            label={translate('resources.series.fields.photo')}
            accept="image/*"
            multiple={false}
            validate={[required()]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <NumberInput
            source="order"
            label={translate('resources.series.fields.order')}
            min={1}
            max={99}
            step={1}
            defaultValue={99}
            validate={[required()]}
            style={{ width: 160 }}
          />
        </FormTab>
        <FormTab label={translate('resources.series.tabs.photo')} path="photo">
          <ImageInput
            source="itemImage_1"
            label="Изображение 1"
            accept="image/*"
            multiple={false}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput
            source="itemImage_2"
            label="Изображение 2"
            accept="image/*"
            multiple={false}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput
            source="itemImage_3"
            label="Изображение 3"
            accept="image/*"
            multiple={false}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab
          // disabled
          label={translate('resources.items.tabs.description')}
          path="description"
        >
          <MyTranslatableInputs groupKey="description_long" singleLocale>
            <ArrayInput
              source="description_long_translated"
              label={translate('resources.items.tabs.description')}
            >
              <SimpleFormIterator fullWidth>
                <TextInput
                  fullWidth
                  source="title"
                  label={'resources.items.fields.title'}
                />
                <TextInput
                  fullWidth
                  source="text"
                  label={'resources.items.fields.text'}
                  multiline
                />
              </SimpleFormIterator>
            </ArrayInput>
          </MyTranslatableInputs>
        </FormTab>
        <FormTab 
          // disabled
          label="resources.items.tabs.advantages" 
          path="advantages"
        >
          <ImagesField />
          <MyTranslatableInputs groupKey="advantages" singleLocale>
            <ArrayInput
              source="advantages_translated"
              label={translate('resources.items.tabs.advantages')}
            >
              <SimpleFormIterator fullWidth>
                <SelectInput
                  source="image"
                  label={translate('resources.items.fields.image')}
                  choices={images}
                />
                <TextInput
                  fullWidth
                  source="title"
                  label={translate('resources.items.fields.title')}
                />
                <TextInput
                  fullWidth
                  source="text"
                  label={translate('resources.items.fields.text')}
                  multiline
                />
              </SimpleFormIterator>
            </ArrayInput>
          </MyTranslatableInputs>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default SeriesCreate;
