import * as React from 'react';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

import { Order } from '../../types';

const Totals = () => {
  const record = useRecordContext<Order>();
  const translate = useTranslate();

  return (
    <Table sx={{ minWidth: '35em' }}>
      <TableBody>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.totalSumm')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            {(record?.totalSummInUSD).toLocaleString(undefined, {
              style: 'currency',
              currency: 'EUR',
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.bonuses')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            {record?.bonuses.toLocaleString(undefined, {
              style: 'currency',
              currency: 'EUR',
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>
            {translate('resources.commands.fields.items.total')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            {(record?.totalSummInUSD - record?.bonuses).toLocaleString(
              undefined,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Totals;
